import { EmoteType } from '@common/types/emote';
import Player from '@common/types/player';

const defaultPlayer: Player = {
  id: '',
  name: 'New Player',
  hasLeft: false,
  isConnected: true,
  cosmetic: {
    color: 'Red',
    avatar: [],
  },
  emoteData: {
    emoteType: EmoteType.Idle,
  },
  secondsRemainingUntilChatEnabled: 0,
};

export default defaultPlayer;
