import { defineSafeStyleConfig } from '../types';

const ButtonTheme = defineSafeStyleConfig({
  baseStyle: {
    borderRadius: 'button',
    textTransform: 'uppercase',
    _hover: {
      // fix for incredibly stupid chakra default theme
      // https://github.com/chakra-ui/chakra-ui/blob/eb0316ddf96dd259433724062e923c33e6eee729/packages/components/theme/src/components/button.ts#L20
      // @ts-expect-error - this is a workaround for a bug in chakra's types, it
      // does actually do what we want to specify null here, even though the
      // type says it should be a string
      _disabled: null,
    },
    _active: {
      opacity: 0.7,
    },
    _disabled: {
      opacity: 0.7,
    },
    fontWeight: 700,
  },
  // Note: We need to explicitly set height: auto because
  // chakra's built-in theme sets these, which breaks our padding
  sizes: {
    '2xs': {
      fontSize: '2xs',
      px: '6px',
      py: '5px',
    },
    xs: {
      fontSize: 'xs',
      px: '12px',
      py: '8px',
    },
    sm: {
      fontSize: 'sm',
      px: '14px',
      py: '18px',
      borderRadius: '20px',
    },
    md: {
      fontSize: 'md',
      px: '24px',
      py: '12px',
      height: 'auto',
    },
  },
  variants: {
    icon: {
      // we need this for e.g. IconButton
      // because chakra sucks, we need to set BOTH of these to 0
      // https://github.com/chakra-ui/chakra-ui/issues/3293#issuecomment-775081618
      minW: 0,
      minWidth: 0,
    },
    primary: {
      bg: 'Purple.Magic',
      color: 'MagicWhite',
    },
    dark: {
      bg: 'MagicBlack',
      color: 'MagicWhite',
    },
    white: {
      bg: 'Neutral.White',
      color: 'MagicBlack',
      _active: {
        opacity: 1,
        bg: 'Neutral.Grey',
      },
      _disabled: {
        opacity: 1,
        bg: 'Neutral.Grey',
        color: 'Neutral.DarkGrey',
      },
    },
    red: {
      bg: 'Red.Magic',
      color: 'MagicWhite',
    },
    outline: {
      bg: 'transparent',
      border: '3px solid',
      borderColor: 'Neutral.White',
      color: 'Neutral.White',
    },
    outlineInverse: {
      bg: 'transparent',
      border: '3px solid',
      borderColor: 'MagicBlack',
    },
    ghost: {
      bg: 'transparent',
      color: 'MagicWhite',
    },
    translucentOutlineButton: {
      background:
        'linear-gradient(135deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 100%)',
      backdropFilter: 'blur(2px)',
      minWidth: 0,
      width: '36px',
      height: '36px',
      padding: 0,
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
});

export default ButtonTheme;
