import { useEffect } from 'react';
import {
  UseRiveOptions,
  UseRiveParameters,
  useRive,
} from '@rive-app/react-canvas';
import { useDesktopWindowScaleFactor } from '@/store/store';
// import { useInterval } from '@/utils';

function useMcRive(
  riveParams?: UseRiveParameters,
  opts: Partial<UseRiveOptions> = {}
) {
  const useRiveResult = useRive(riveParams, opts);
  const scaleFactor = useDesktopWindowScaleFactor();

  // All Rive components need to be resized when the window scale factor changes.
  useEffect(() => {
    useRiveResult.rive?.resizeDrawingSurfaceToCanvas();
  }, [scaleFactor]);

  /**
   * There is a bug in the Rive React runtime in Chrome where an intersection observer is calling stopRendering
   * when certain intersections happen, but it is not rightly calling startRendering to resume the animation.
   * We are adding an interval to call startRendering every 100ms to ensure that our Rive components don't get "stuck".
   *
   * Bug Report: https://github.com/rive-app/rive-react/issues/257
   * Rive Community Conversation: https://rive.app/community/forums/support/fsnLLkXUaA62/react-rive-animations-getting-stuck/ftTlrwM2cxuq
   *
   * UPDATE: 5/29/2024: The bug has been fixed in the latest version of the Rive React runtime. We can remove this interval.
   *        We will keep this code here for a few weeks in case we need to quickly revert back to this fix.
   */

  // useInterval(() => {
  //   useRiveResult?.rive?.startRendering();
  // }, 100);

  return useRiveResult;
}

export default useMcRive;
