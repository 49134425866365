import { useCallback } from 'react';
import { LobbyData } from '@common/games/Lobby/types';
import { RoomData } from '@common/games/Room/types';
import { Identifiers } from '@common/identifiers';
import { generateRandomDefaultUserStyle } from '@common/resources/avatars/generateRandomDefaultUserStyle';
import { GameNameIncludingLobby } from '@common/types/games';
import Player, { PlayerId, PlayerOrId } from '@common/types/player';
import { getValidPublicRoomIds } from '@common/types/room-info';
import { IState } from '@common/types/state';
import { UnboundUserStyle } from '@common/types/user';
import { Immutable } from '@common/utils';
import { DrawerType } from '@components/SystemDrawer/Content';
import type { DiscordSDK } from '@discord/embedded-app-sdk';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { isEqual } from 'lodash';
import defaultPlayer from 'src/constants/default-player';
import { persistedAtom, selectAtomDeepEquals } from './utils';
import { getDecoration } from '@/constants/decorations';
import { deploymentVersion, isDesktopMode, surface } from '@/environment';
import { GameName } from '@/games';
import { getCurrentRoomId } from '@/utils';

const searchParams = new URL(window.location.href).searchParams;
const queryParams = Object.fromEntries(searchParams.entries());
export const queryParametersAtom = atom<Record<string, string>>(queryParams);
export const useQueryParameters = () => useAtomValue(queryParametersAtom);

export const isLoadingAnimationVisibleAtom = atom<boolean>(true);

export const roomPortalAtom = atom<string | null>(null);

export const useIsInPublicRoom = () => {
  const roomPortal = useAtomValue(roomPortalAtom);
  if (surface === 'discord') {
    return roomPortal === null ? false : true;
  } else {
    const currentRoomId = getCurrentRoomId();
    const validPublicRoomIds = getValidPublicRoomIds(deploymentVersion);
    return currentRoomId && validPublicRoomIds.includes(currentRoomId);
  }
};

/*
 * gameState
 */
export const stateAtom = atom<Immutable<IState<RoomData>>>({
  scope: 'Room',
  data: {
    players: [],
    timer: {
      totalSeconds: 0,
      secondsRemaining: 0,
      progress: 0,
      isRunning: false,
    },
    isGameStartingCountdownVisible: false,
    animationInProgress: false,
    hostPlayerId: null,
    roomSessionId: '',
    chat: [],
  },
  child: {
    scope: 'Lobby',
    data: { selectedGame: null, gameVotes: {} },
    child: null,
  } satisfies IState<LobbyData>,
} satisfies IState<RoomData>);
export const useGameState = () => useAtomValue(stateAtom);

/*
 * playerId
 */
export const playerIdAtom = persistedAtom<PlayerId>(
  'playerId',
  // When running in Discord, we use a temporary player ID until the user is
  // authenticated and we know their actual Discord user ID, which we then use
  // as the player ID.
  // We set this meaningless temp value to make it clear that the player ID is
  // not known until the user is authenticated.
  surface === 'discord' ? defaultPlayer.id : Identifiers.generatePlayerId()
);
export const usePlayerId = () => useAtomValue(playerIdAtom);

export const anonymousUserStyleAtom = persistedAtom<UnboundUserStyle>(
  'anonymousUserStyle',
  generateRandomDefaultUserStyle()
);

/*
 * roomSessionId
 */
export const roomSessionIdAtom = selectAtomDeepEquals(
  stateAtom,
  ({ data }) => data.roomSessionId
);

const hostPlayerIdAtom = selectAtomDeepEquals(
  stateAtom,
  ({ data }) => data.hostPlayerId
);

export const useHostPlayerId = () => useAtomValue(hostPlayerIdAtom);

export const useHost = () => {
  const players = usePlayersIncludingLeavers();
  const hostPlayerId = useHostPlayerId();
  return players.find((p) => p.id === hostPlayerId);
};

export const isHostAtom = atom<boolean | null>((get) => {
  const hostPlayerId = get(hostPlayerIdAtom);
  const playerId = get(playerIdAtom);
  // If there is no host, such as before we've connected to the server, return null
  if (!hostPlayerId) return null;
  return hostPlayerId === playerId;
});

/*
 * player
 */
export const playerAtom = atom<Player>((get) => {
  const playerId = get(playerIdAtom);
  const allPlayers = get(playersIncludingLeaversAtom);
  const player = allPlayers.find((p) => p.id === playerId);
  return player ?? defaultPlayer;
});
export const usePlayer = () => useAtomValue(playerAtom);

const cosmeticAtom = selectAtomDeepEquals(
  playerAtom,
  (player) => player.cosmetic
);
export const useCosmetic = () => useAtomValue(cosmeticAtom);

export const avatarAtom = selectAtomDeepEquals(
  cosmeticAtom,
  (cosmetic) => cosmetic.avatar
);

export const useAvatar = () => useAtomValue(avatarAtom);

const playerNameAtom = selectAtomDeepEquals(
  playerAtom,
  (player) => player.name
);
export const usePlayerName = () => useAtomValue(playerNameAtom);

export const colorAtom = selectAtomDeepEquals(
  cosmeticAtom,
  (cosmetic) => cosmetic.color
);
export const useColor = () => useAtomValue(colorAtom);
export const useUserColor = () => {
  const color = useColor();
  return getDecoration(color);
};

/*
 * players
 */
const playersIncludingLeaversAtom = selectAtomDeepEquals(
  stateAtom,
  ({ data }) => data.players
);

export const playersWithoutLeaversAtom = atom((get) =>
  get(playersIncludingLeaversAtom).filter((p) => !p.hasLeft)
);

export const numPlayersWithoutLeaversAtom = atom(
  (get) => get(playersWithoutLeaversAtom).length
);

export const useNumPlayersWithoutLeavers = () =>
  useAtomValue(numPlayersWithoutLeaversAtom);

export const usePlayersWithoutLeavers = () =>
  useAtomValue(playersWithoutLeaversAtom);
export const usePlayersIncludingLeavers = () =>
  useAtomValue(playersIncludingLeaversAtom);

export const usePlayerById = (id: PlayerId | null | undefined) => {
  const players = usePlayersIncludingLeavers();
  return players.find((p) => p.id === id);
};

export const usePlayerByPlayerOrId = (playerOrId: PlayerOrId) => {
  const players = usePlayersIncludingLeavers();
  if (typeof playerOrId === 'string') {
    const player = players.find((p) => p.id === playerOrId);
    return player;
  }
  return playerOrId;
};

export const usePlayersMeFirst = () => {
  const me = usePlayer();
  const others = usePlayersWithoutLeavers().filter((p) => p.id !== me.id);
  return [me, ...others].filter((p) => !!p);
};

export const accountCreationMiniModalAtom = atom<boolean>(false);

export function useOpenAccountCreationMiniModal() {
  const setIsOpen = useSetAtom(accountCreationMiniModalAtom);
  return useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
}

/*
 * drawerType
 */
export const drawerTypeAtom = atom<DrawerType | null>(null);
export const useDrawerType = () => useAtomValue(drawerTypeAtom);

export function useOpenDrawer() {
  // Don't use useIsHost here, as it creates a circular dependency
  const setDrawerType = useSetAtom(drawerTypeAtom);
  return useCallback(
    (drawerType: DrawerType) => {
      setDrawerType(drawerType);
    },

    [setDrawerType]
  );
}

export function useCloseDrawer() {
  const setDrawerType = useSetAtom(drawerTypeAtom);
  return () => {
    console.log('calling closeDrawer()');
    setDrawerType(null);
  };
}

/**
 * SlapperContent
 */
const slapperContentAtom = atom<React.ReactNode>(null);
export const useSlapperContent = () => useAtomValue(slapperContentAtom);
export const useSetSlapperContent = () => useSetAtom(slapperContentAtom);

/*
 * currentGameName
 */
export const currentGameNameAtom = atom<GameNameIncludingLobby>((get) => {
  const state = get(stateAtom);
  return (state.child?.scope as GameName) ?? 'Lobby';
});
/**
 * Returns the current game name, defaulting to 'Lobby' if the gameName is null (e.g., before we've connected to the server).
 */
export const useCurrentGameName = () => useAtomValue(currentGameNameAtom);

export const discordSdkAtom = atom<DiscordSDK | undefined>(undefined);

/**
 * Atom to store the Discord access token. Should only be used in the 'discord' surface.
 */
export const discordAccessTokenAtom = atom<string | undefined>(undefined);

// for systemDrawer resizing, we need to access the scaling ratio in different levels of components (e.g., AvatarPartHighlight.tsx)
export const drawerScaleFactorAtom = atom(1);
export const useDrawerScaleFactor = () => useAtomValue(drawerScaleFactorAtom);
export const useSetDrawerScaleFactor = () => useSetAtom(drawerScaleFactorAtom);

// for game window resizing, we need to access the scaling ratio in different levels of components (.e.g, mcmind animations)
export const desktopWindowScaleFactorAtom = atom(1);
export const useDesktopWindowScaleFactor = () =>
  useAtomValue(desktopWindowScaleFactorAtom);
export const useSetDesktopWindowScaleFactor = () =>
  useSetAtom(desktopWindowScaleFactorAtom);

/**
 * Audio settings
 */

export const soundEffectsVolumeAtom = persistedAtom<number>(
  'soundEffectsVolumeAtom',
  0.1
);
export const useSoundEffectsVolume = () => useAtomValue(soundEffectsVolumeAtom);
export const useSetSoundEffectsVolume = () =>
  useSetAtom(soundEffectsVolumeAtom);

export const musicVolumeAtom = persistedAtom<number>(
  'musicVolumeAtom',
  isDesktopMode ? 0.04 : 0.02 // If mobile, set default volume to 0.02 since it may be annoying for music to be playing from multiple phones in-person
);
export const useMusicVolume = () => useAtomValue(musicVolumeAtom);
export const useSetMusicVolume = () => useSetAtom(musicVolumeAtom);

export const isSoundEffectsMuteAtom = persistedAtom<boolean>(
  'isSoundEffectsMuteAtom',
  false
);
export const useIsSoundEffectsMute = () => useAtomValue(isSoundEffectsMuteAtom);
export const useSetIsSoundEffectsMute = () =>
  useSetAtom(isSoundEffectsMuteAtom);

export const isMusicMuteAtom = persistedAtom<boolean>('isMusicMuteAtom', false);
export const useIsMusicMute = () => useAtomValue(isMusicMuteAtom);
export const useSetIsMusicMute = () => useSetAtom(isMusicMuteAtom);

export const isShowingHowToPlayAtom = atom<boolean>(false);
export const useIsShowingHowToPlay = () => useAtomValue(isShowingHowToPlayAtom);
export const useSetIsShowingHowToPlay = () =>
  useSetAtom(isShowingHowToPlayAtom);

export const isDiscordHardwareAccelerationEnabledAtom = atom<boolean>(true);
export const useIsDiscordHardwareAccelerationEnabled = () =>
  useAtomValue(isDiscordHardwareAccelerationEnabledAtom);

export const authenticationFailureAtom = atom<unknown>(false);

export const discordSpeakingUsersAtom = atom<string[]>([]);

export const discordActivityInstanceParticipantUserIdsAtom = atom<string[]>([]);

export function usePlayersInDiscordActivityInstance() {
  const players = usePlayersWithoutLeavers();
  const discordActivityInstanceParticipantUserIds = useAtomValue(
    discordActivityInstanceParticipantUserIdsAtom
  );
  return players.filter((player) => {
    // Note: we assume that the player's ID is their Discord user ID
    // This should always be true for users playing on the discord surface,
    // which is the only place this hook is used.
    const playerDiscordId = player.id;
    return discordActivityInstanceParticipantUserIds.includes(playerDiscordId);
  });
}

/**
 * Hook to determine if a Discord user is currently speaking.
 *
 * This hook checks if the specified user is speaking on Discord. It will return
 * false on non-Discord platforms or if the user doesn't have permission to
 * access the target player's voice activity (e.g., they are muted).
 *
 * @param {PlayerOrId} playerOrId - The player or player ID to check.
 * @returns {boolean} - Returns true if the user is speaking, false otherwise.
 *
 * @example
 * const isSpeaking = useIsUserSpeaking(playerOrId);
 */
export const useIsUserSpeaking = (playerOrId: PlayerOrId): boolean => {
  const player = usePlayerByPlayerOrId(playerOrId);
  const speakingUsers = useAtomValue(discordSpeakingUsersAtom);
  if (!player) return false;
  // Note: we assume that the player's ID is their Discord user ID
  // This should always be true for users playing on the discord surface,
  // which is the only place this hook is used.
  const discordUserId = player.id;
  return speakingUsers.includes(discordUserId);
};

export const isBreadToasterWindowOpenAtom = atom(false);
export const useSetIsBreadToasterWindowOpen = () =>
  useSetAtom(isBreadToasterWindowOpenAtom);

export const useIsBreadToasterWindowOpen = () =>
  useAtomValue(isBreadToasterWindowOpenAtom);

export const avatarRefCountsAtomFamily = atomFamily((_playerId: PlayerId) => {
  return atom<number>(0);
}, isEqual);

export const useAvatarRefCount = (playerId: PlayerId) => {
  const [refCount, setRefCount] = useAtom(avatarRefCountsAtomFamily(playerId));

  const incrementRefCount = () => {
    setRefCount((prevCount) => prevCount + 1);
  };

  const decrementRefCount = () => {
    setRefCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
  };

  return {
    refCount,
    incrementRefCount,
    decrementRefCount,
  };
};

export const isUserAuthenticatedAtom = atom<boolean>(false);

export const useIsUserAuthenticated = () =>
  useAtomValue(isUserAuthenticatedAtom);

const isDiscordServerCoverSheetOpenAtom = atom<boolean>(false);

export const useIsDiscordServerCoverSheetOpen = () =>
  useAtomValue(isDiscordServerCoverSheetOpenAtom);

export const useSetIsDiscordServerCoverSheetOpen = () =>
  useSetAtom(isDiscordServerCoverSheetOpenAtom);

const mutedPlayersAtom = atom<PlayerId[]>([]);

export const useMutedPlayers = () => useAtomValue(mutedPlayersAtom);

export const useToggleMutedPlayer = () => {
  const setMutedPlayers = useSetAtom(mutedPlayersAtom);
  return (playerId: PlayerId) => {
    setMutedPlayers((prev) =>
      prev.includes(playerId)
        ? prev.filter((id) => id !== playerId)
        : [...prev, playerId]
    );
  };
};

export const useIsPlayerMuted = (playerId: PlayerId) => {
  const mutedPlayers = useMutedPlayers();
  return mutedPlayers.includes(playerId);
};

const isReportPlayerModalOpenAtom = atom<boolean>(false);

export const useIsReportPlayerModalOpen = () =>
  useAtomValue(isReportPlayerModalOpenAtom);

export const useSetIsReportPlayerModalOpen = () =>
  useSetAtom(isReportPlayerModalOpenAtom);

export const useOpenReportPlayerModal = () => {
  const setIsReportPlayerModalOpen = useSetIsReportPlayerModalOpen();
  return () => {
    setIsReportPlayerModalOpen(true);
  };
};

export const useCloseReportPlayerModal = () => {
  const setIsReportPlayerModalOpen = useSetIsReportPlayerModalOpen();
  return () => {
    setIsReportPlayerModalOpen(false);
  };
};

export const inappropriateContentAtom = atom<
  | {
      content: string;
      reason: string;
    }
  | undefined
>(undefined);
